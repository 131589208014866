
import { defineComponent } from "vue";


export default defineComponent({
  name: "HomeSliderSlide",
  props: {
    show: Boolean,
  },
});
