<template>
  <div class="home bg-light">

    <app-home-slider/>

    <section class="py-8 relative">
      <div class="container grid grid-cols-1 md:grid-cols-3 gap-4">

        <div
          class="px-4 py-6 rounded-lg bg-white shadow-md text-center flex flex-col
          justify-between items-center card"
        >
          <div>
            <div class="mb-3">
              <img class="mx-auto h-24" src="/img/home/card-latas.png"/>
            </div>
            <div class="text-primary leading-4 mb-3 font-bold text-sm lead">
              Descubra nuestra gama completa de productos y elija la opción 
              adecuada para el bebé.
            </div>
          </div>
          <router-link
            class="py-1 px-8 rounded-full uppercase border-2 focus:outline-none
            border-secondary bg-primary text-white font-bold text-xs"
            :to="{ name: 'Portfolio' }"
          >
            Portafolio
          </router-link>
        </div>
        <div
          class="px-4 py-6 rounded-lg bg-white shadow-md text-center flex flex-col
          justify-between items-center card"
        >
          <div>
            <div class="mb-3">
              <img class="mx-auto h-24" src="/img/home/card-medico-a.png"/>
            </div>
            <div class="text-primary leading-4 mb-3 font-bold text-sm lead">
              Consulte los materiales científicos más recientes que hemos 
              desarrollado para usted, para que siempre se ponga al día en 
              materia de innovación y avances científicos.
            </div>
          </div>
          <router-link
            class="py-1 px-8 rounded-full uppercase border-2 focus:outline-none
            border-secondary bg-primary text-white font-bold text-xs"
            :to="{ name: 'Publications' }"
          >
            Biblioteca
          </router-link>
        </div>
        <div
          class="px-4 py-6 rounded-lg bg-white shadow-md text-center flex flex-col
          justify-between items-center card"
        >
          <div>
            <div class="mb-3">
              <img class="mx-auto h-24" src="/img/home/card-medico-b.png"/>
            </div>
            <div class="text-primary leading-4 mb-3 font-bold text-sm lead">
              Ayude a los padres, incluso a distancia, compartiendo estos 
              materiales educativos.
            </div>
          </div>
          <router-link
            class="py-1 px-8 rounded-full uppercase border-2 focus:outline-none
            border-secondary bg-primary text-white font-bold text-xs
            whitespace-nowrap"
            :to="{ name: 'Poop' }"
          >
            Materials para papás
          </router-link>
        </div>
      </div>
    </section>

    <section class="bg-white">
      <div class="container py-4 grid grid-cols-1 md:grid-cols-2">
        <div class="flex flex-col justify-center">
          <div class="text-primary font-bold text-3xl mb-6">
            ¡Nuestra nueva aplicación finalmente está aquí para usted!
          </div>
          <div class="text-primary mb-6">
            Estamos muy contentos de anunciarle que nuestra nueva App ya está disponible para descargar para teléfonos iPhone o Android!
          </div>
          <div class="flex flex-row justify-start items-center">
            <a 
              class="w-28 mr-4"
              target="_blank"
              title="App Store ─ Apple"
              href="https://apps.apple.com/pt/app/friso-vademécum/id1163693380"
            >
              <img src="/img/btn-app-store.png"/>
            </a>
            <a
              class="w-28"
              target="_blank"
              title="Google Play"
              href="https://play.google.com/store/apps/details?id=com.metacriacoes.frisocatalog.android"
            >
              <img src="/img/btn-play-store.png"/>
            </a>
          </div>
        </div>
        <div class="flex flex-row-reverse justify-end items-center">
          <img class="-ml-16 w-3/4" src="/img/home/app-devices.png"/>
          <img class="pt-40" src="/img/home/qr-code.png"/>
        </div>
      </div>
    </section>

    <section>
      <div class="container py-10 text-center">
        <div class="text-primary font-bold text-3xl mb-2">
          No tengas más dudas...
        </div>
        <div class="text-primary mb-6">
          Escriba su correo y pronto nos pondermos en contacto con usted.
        </div>

        <form
          class="w-80 mx-auto"
          @submit.prevent="submitHelpEmail"
        >
          <input
            required
            type="email"
            class="w-full py-2 px-4 rounded-full mb-6 text-sm focus:outline-none
            shadow-around"
            placeholder="INTRODUZCA CORREO ELECTRÓNICO"
            v-model="helpEmail"
          />
          <input
            required
            type="text"
            class="w-full py-2 px-4 rounded-full mb-6 text-sm focus:outline-none
            shadow-around"
            placeholder="ESCRIBA AQUÍ SU PREGUNTA"
            v-model="helpMessage"
          />
          <button
            v-if="isSending"
            type="button"
            class="py-2 px-4 rounded-full uppercase border-2 focus:outline-none
            border-secondary bg-primary opacity-50 text-white font-bold text-sm w-full"
          >
            Enviando...
          </button>
          <button
            v-else-if="sentWithError"
            type="button"
            class="py-2 px-4 rounded-full uppercase border-2 focus:outline-none
            border-secondary bg-red-700 text-white font-bold text-sm w-full"
          >
            ¡Se ha producido un error!
          </button>
          <button
            v-else-if="isSent"
            type="button"
            class="py-2 px-4 rounded-full uppercase border-2 focus:outline-none
            border-secondary bg-green-700 text-white font-bold text-sm w-full"
          >
            ¡Enviado!
          </button>
          <button
            v-else
            type="submit"
            class="py-2 px-4 rounded-full uppercase border-2 focus:outline-none
            border-secondary bg-primary text-white font-bold text-sm w-full"
          >
            Solicitar contacto
          </button>
        </form>

      </div>
    </section>

  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import axios from "axios";
import HomeSlider from "@/components/HomeSlider.vue";


export default defineComponent({
  name: "Home",
  components: {
    "app-home-slider": HomeSlider,
  },
  setup() {
    const helpEmail = ref();
    const helpMessage = ref();
    const isSending = ref(false);
    const isSent = ref(false);
    const sentWithError = ref(false);

    const submitHelpEmail = () => {
      isSending.value = true;
      console.log("submitHelpEmail():", helpEmail.value, helpMessage.value);
      axios.post("https://api.metacriacoes.com/friesland-campina/app-friso/v1/", {
        action: "requestContact",
        email: helpEmail.value,
        message: helpMessage.value,
      })
      .then(resp => resp.data)
      .then(data => {
        isSending.value = true;
        if(data.success) {
          isSent.value = true;
          setTimeout(() => {
            isSent.value = false;
            helpEmail.value = "";
            helpMessage.value = "";
          }, 3000);
        } else {
          sentWithError.value = true;
          helpEmail.value = "";
          helpMessage.value = "";
        }
      })
      .catch(err => {
        console.error(err);
        sentWithError.value = true;
        helpEmail.value = "";
        helpMessage.value = "";
      })
      .finally(() => {
        isSending.value = false;
      })

    }

    return { helpEmail, helpMessage, submitHelpEmail, isSending, isSent, sentWithError };
  }
});
</script>

<style scoped lang="scss">
.card {
  background-image: url('/img/home/card-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top left;
}
</style>
