<template>
  <div class="holder overflow-hidden">

    <app-home-slider-slide class="slide-1" :show="currSlide === 1" @hover="handleHover">
      <div class="container grid grid-cols-2 md:grid-cols-2 py-8">
        <div>
          <img
            class="w-52 md:w-72 lg:w-108 mx-auto"
            src="/img/home/slides/1/packshot.png"
          />
        </div>
        <div class="flex flex-col justify-center">
          <div
            class="font-display text-primary leading-7 md:leading-10 font-bold text-3xl md:text-5xl lg:text-7xl mb-4"
          >
            Nueva Friso<br/>
            Comfort Next
          </div>
          <div
            class="text-primary font-extrabold text-md md:text-xl mb-6"
          >
            Con probióticos y 3 fibras dietéticas.
          </div>
          <div>
            <router-link 
              class="text-sm md:text-base px-4 py-1 md:px-8 rounded-full uppercase border-2 focus:outline-none
              border-secondary bg-primary text-white font-bold"
              :to="{ name: 'Product', params: { slug: 'friso-gold-comfort-next' } }"
            >
              ¡Descubra&nbsp;más!
            </router-link>
          </div>
        </div>
      </div>
    </app-home-slider-slide>
    
    <app-home-slider-slide class="slide-2" :show="currSlide === 2" @hover="handleHover">
      <div class="container grid grid-cols-2 md:grid-cols-2 py-8">
        <div class="image-holder">
          <img src="/img/home/slides/2/device.png"/>
        </div>
        <div class="flex flex-col justify-center">
          <div
            class="font-display text-primary leading-7 md:leading-10 font-bold text-3xl md:text-5xl lg:text-7xl mb-4"
          >
            Calculadoras
          </div>
          <div
            class="text-primary font-extrabold text-md md:text-xl mb-6"
          >
            Con el sello de confianza de la OMS, ahora pude calcular los percentiles de crecimiento de los bebés al instante.
          </div>
          <div>
            <router-link 
              class="text-sm md:text-base px-4 py-1 md:px-8 rounded-full uppercase border-2 focus:outline-none
              border-secondary bg-primary text-white font-bold"
              :to="{ name: 'Calculator', params: { slug: 'head-circumference-age-zero-to-five' } }"
            >
              ¡Descubra&nbsp;más!
            </router-link>
          </div>
        </div>
      </div>
    </app-home-slider-slide>

    <app-home-slider-slide class="slide-3" :show="currSlide === 3" @hover="handleHover">
      <div class="container text-lg font-extrabold py-8">
        Slide C
      </div>
    </app-home-slider-slide>

    <div class="absolute top-0 right-0 flex flex-col p-3" v-if="slideCount > 1">
      <button
        v-for="n in slideCount"
        :key="n"
        :class="{ active: currSlide === n }"
        class="circle mb-1 rounded-full h-3 w-3 focus:outline-none font-bold"
        @click="currSlide = n"
      />
    </div>

  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import HomeSliderSlide from "@/components/HomeSliderSlide.vue";

export default defineComponent({
  name: "HomeSlider",
  components: {
    "app-home-slider-slide": HomeSliderSlide,
  },
  setup () {
    const slideCount = 2;
    const currSlide = ref(1);
    const t = 6000;
    let interv: number;

    const updateSlide = () => {
      if ( currSlide.value >= slideCount ) {
        currSlide.value = 1;
      } else currSlide.value++;
    }

    const play = () => {
      interv = setInterval(() => {
        updateSlide();
      }, t);
    };

    const pause = () => {
      clearInterval(interv);
    };

    const handleHover = (isHover: boolean) => {
      if(slideCount > 1) {
        if(isHover) pause();
        else play();
      }
    }

    if(slideCount > 1) play();

    return { currSlide, slideCount, handleHover };
  }
});
</script>

<style scoped lang="scss">

$slider-height: 700px;
$slider-margin-bottom: -170px;

.holder {
  position: relative;
  height: $slider-height - 300px;
  margin-bottom: $slider-margin-bottom;

  @media (min-width: 768px) {
    height: $slider-height - 100px;
  }

  @media (min-width: 976px) {
    height: $slider-height;
  }

  button.circle  {
    background-color: rgba(0,0,0,.2);
    transition: all .15s ease-out;

    &.active {
      @apply bg-primary;
    }
  }
}

.slide-1,
.slide-2 {
  background-image: url('/img/home/slides/1/bg.png');
  background-position: center bottom;
}

.slide-2 {

  
  .image-holder {
    margin-right: -80px;
    margin-top: 40px;
    @media (min-width: 768px) {
      margin-right: -160px;
      margin-top: 140px;
    }
  }
}


.slide-3 {
  @apply bg-blue-200; 
}

</style>
