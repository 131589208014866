<template>
  <transition name="appear">
    <div
      class="slide h-full"
      v-if="show"
      @mouseover="$emit('hover', true)"
      @mouseleave="$emit('hover', false)"
    >
      <div class="slide-overlay h-full flex flex-col justify-center pb-36">
        <slot/>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent } from "vue";


export default defineComponent({
  name: "HomeSliderSlide",
  props: {
    show: Boolean,
  },
});
</script>

<style scoped lang="scss">
.appear-enter-active,
.appear-leave-active {
  transition: all 0.15s ease-out;
}

.appear-enter-to,
.appear-leave-from {
  opacity: 1;
}

.appear-enter-from, 
.appear-leave-to {
  opacity: 0;
}

.slide {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  .slide-overlay {
    position: relative;
    background-image: url('/img/home/slides/bg-overlay.png');
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center bottom;
  }
}
</style>
