
import { defineComponent, ref } from "vue";
import HomeSliderSlide from "@/components/HomeSliderSlide.vue";

export default defineComponent({
  name: "HomeSlider",
  components: {
    "app-home-slider-slide": HomeSliderSlide,
  },
  setup () {
    const slideCount = 2;
    const currSlide = ref(1);
    const t = 6000;
    let interv: number;

    const updateSlide = () => {
      if ( currSlide.value >= slideCount ) {
        currSlide.value = 1;
      } else currSlide.value++;
    }

    const play = () => {
      interv = setInterval(() => {
        updateSlide();
      }, t);
    };

    const pause = () => {
      clearInterval(interv);
    };

    const handleHover = (isHover: boolean) => {
      if(slideCount > 1) {
        if(isHover) pause();
        else play();
      }
    }

    if(slideCount > 1) play();

    return { currSlide, slideCount, handleHover };
  }
});
